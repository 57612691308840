<template>
  <section>
    <folha v-for="(elementos, indexFolha) in listaDeFetos" :key="indexFolha">
      <h3 v-if="via == '2' && indexFolha === 0">2ª VIA DO LAUDO</h3>

      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />

      <div v-if="indexFolha === 0">
        <br />
        <br />
        <br />
        <br />
        <div class="header maiuscula" style="">
          <h3><strong>NOME:</strong> {{ paciente.nome }}</h3>
          <h3><strong>DATA DO EXAME:</strong> {{ consulta.data }}</h3>
          <br />
        </div>

        <br />
        <br />
        <h3>PERFIL BIOFÍSICO FETAL</h3>
        <br />
        <br />
      </div>

      <div v-for="(elemento, indexElemento) in elementos" :key="indexElemento">
        <h4 v-if="titulos.includes(elemento)">
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>

          {{ elemento }}
          <p></p>
          <p></p>
          <p></p>
          <p></p>
        </h4>
        <h3 v-else-if="elemento === 'feto0'">
          <strong> Feto 1</strong>
        </h3>
        <h3 v-else-if="elemento === 'feto1'">
          <strong> Feto 2</strong>
        </h3>
        <h3 v-else-if="elemento === 'feto2'">
          <strong> Feto 3</strong>
        </h3>
        <h3 v-else-if="elemento === 'feto3'">
          <strong> Feto 4</strong>
        </h3>
        <h3 v-else-if="elemento === 'feto4'">
          <strong> Feto 5</strong>
        </h3>
        <p v-else-if="elemento === 'espaço'"></p>
        <div v-else>
          {{ elemento }}
        </div>
      </div>
      <br />
      <br />
      <footer v-if="indexFolha + 1 == listaDeFetos.length">
        <span
          ><strong
            >Dra
            {{
              medico.nome == 'Adriana Melo (curso)'
                ? 'Adriana Melo'
                : medico.nome
            }}</strong
          > </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>
    </folha>
  </section>
</template>

<script>
import ImprecoesMixins from '@mixins/ImprecoesMixins';
import moment from 'moment';
export default {
  mixins: [ImprecoesMixins],

  methods: {
    dataFormatada(val) {
      return moment(val).format('DD-MM-YYYY');
    },
  },

  mounted() {
    if (this.$route.query.via) this.via = this.$route.query.via;
    let folha = [];
    let arrayDeFetos = [];
    const todos = [];

    for (let i = 0; i < this.laudo.length; i++) {
      if (this.laudo.length > 1) folha.push('espaço');
      if (this.laudo.length > 1) folha.push('espaço');
      if (this.laudo.length > 1) folha.push('espaço');
      if (this.laudo.length > 1) folha.push(`feto${i}`);
      if (this.laudo.length > 1) folha.push('espaço');
      if (this.laudo.length > 1) folha.push('espaço');
      if (this.laudo.length > 1) folha.push('espaço');
      if (this.laudo.length > 1) folha.push('espaço');
      folha.push('espaço');

      if (this.laudo[i].indicacaoExame)
        folha.push(`Indicação do exame: ${this.laudo[i].indicacaoExame}.`);

      if (this.laudo.length == 1) folha.push(`Gestação única. `);
      if (this.laudo.length == 2) folha.push(`Gestação gemelar. `);
      if (this.laudo.length == 3) folha.push(`Gestação trigemelar. `);
      if (this.laudo.length == 4) folha.push(`Gestação quadrigemelar. `);
      if (this.laudo.length == 5) folha.push(`Gestação quíntuplo. `);
      if (folha.length > 0) folha.splice(0, 0, '');
      this.gestacaoz = [...folha];
      folha = [];

      if (this.laudo[i].situacao)
        folha.push(
          `Situação ${this.laudo[i].situacao}${
            this.laudo[i].apresentacao || this.laudo[i].dorso ? ',' : ''
          }${this.laudo[i].apresentacao ? ' apresentação ' : ''}${
            this.laudo[i].apresentacao ? this.laudo[i].apresentacao : ''
          }${this.laudo[i].dorso ? ' com dorso ' : ' '}${
            this.laudo[i].dorso ? this.laudo[i].dorso.toLowerCase() + ' ' : ''
          }${this.laudo[i].dorso2 ? (' ') + this.laudo[i].dorso2.toLowerCase() : ''}.`
        );
      if (folha.length > 0) folha.splice(0, 0, 'Posição fetal');
      this.posiçãoFetal = [...folha];
      folha = [];

      if (this.laudo[i].diametroBiparietal && this.laudo[i].percentil1)
        folha.push(
          `DBP (diâmetro biparietal): ${this.laudo[i].diametroBiparietal} mm (percentil ${this.laudo[i].percentil1}).`
        );

      if (this.laudo[i].diametroBiparietal && !this.laudo[i].percentil1)
        folha.push(
          `DBP (diâmetro biparietal): ${this.laudo[i].diametroBiparietal} mm.`
        );

      if (this.laudo[i].circunferenciaCraniana && this.laudo[i].percentil2)
        folha.push(
          `CC (circunferência craniana): ${this.laudo[i].circunferenciaCraniana} mm (percentil ${this.laudo[i].percentil2}).`
        );
      if (this.laudo[i].circunferenciaCraniana && !this.laudo[i].percentil2)
        folha.push(
          `CC (circunferência craniana): ${this.laudo[i].circunferenciaCraniana} mm.`
        );

      if (this.laudo[i].circunferenciaAbdominal && this.laudo[i].percentil3)
        folha.push(
          `CA (circunferência abdominal): ${this.laudo[i].circunferenciaAbdominal} mm (percentil ${this.laudo[i].percentil3}).`
        );
      if (this.laudo[i].circunferenciaAbdominal && !this.laudo[i].percentil3)
        folha.push(
          `CA (circunferência abdominal): ${this.laudo[i].circunferenciaAbdominal} mm.`
        );

      if (this.laudo[i].comprimentoFemoral && this.laudo[i].percentil4)
        folha.push(
          `CF (comprimento fêmur): ${this.laudo[i].comprimentoFemoral} mm (percentil ${this.laudo[i].percentil4}).`
        );
      if (this.laudo[i].comprimentoFemoral && !this.laudo[i].percentil4)
        folha.push(
          `CF (comprimento fêmur): ${this.laudo[i].comprimentoFemoral} mm.`
        );

      if (this.laudo[i].pesoFetal && this.laudo[i].percentil5)
        folha.push(
          `Peso fetal estimado de ${this.laudo[i].pesoFetal} gramas, correspondendo ao percentil ${this.laudo[i].percentil5}.`
        );
      if (this.laudo[i].pesoFetal && !this.laudo[i].percentil5)
        folha.push(`Peso fetal estimado de ${this.laudo[i].pesoFetal} gramas.`);

      if (folha.length > 0) folha.splice(0, 0, 'Biometria fetal');
      this.biometriaFetal = [...folha];
      folha = [];

      if (
        this.laudo[i].batimentosCardiacosFetais &&
        this.laudo[i].batimentosCardiacosFetais != 'presentes'
      )
        folha.push(
          `Batimentos cardíacos fetais ${this.laudo[i].batimentosCardiacosFetais}}`
        );

      if (
        this.laudo[i].batimentosCardiacosFetais &&
        this.laudo[i].batimentosCardiacosFetais === 'presentes'
      )
        folha.push(
          `Batimentos cardíacos fetais ${
            this.laudo[i].batimentosCardiacosFetais
          }${
            this.laudo[i].batimentosCardiacosFetais &&
            this.laudo[i].batimentosCardiacosFetais2
              ? ', '
              : ''
          }${
            this.laudo[i].batimentosCardiacosFetais2
              ? this.laudo[i].batimentosCardiacosFetais2
              : ''
          }${
            this.laudo[i].batimentosCardiacosFetais2 &&
            this.laudo[i].frequenciabpm
              ? ', '
              : ''
          }${
            this.laudo[i].frequenciabpm
              ? ' com frequência cardíaca de ' +
                this.laudo[i].frequenciabpm +
                ' bpm'
              : ''
          }.`
        );

      if (this.laudo[i].movimentosCorporais)
        folha.push(
          `Movimentos corporais ${this.laudo[i].movimentosCorporais}. `
        );
      if (this.laudo[i].tonus) folha.push(`Tônus ${this.laudo[i].tonus}. `);
      if (this.laudo[i].volumeLíquidoAmniotico)
        folha.push(
          `Volume de líquido amniótico ${this.laudo[i].volumeLíquidoAmniotico}${
            this.laudo[i].percentil6 ? ' com maior bolsão medindo ' : ''
          }${this.laudo[i].percentil6 ? this.laudo[i].percentil6 : ''}${
            this.laudo[i].percentil6 ? ' cm' : ''
          }. `
        );

      if (this.laudo[i].placenta)
        folha.push(
          `Placenta ${this.laudo[i].placenta}, ${
            this.laudo[i].insercao
              ? 'de inserção ' + this.laudo[i].insercao + ','
              : ''
          }${
            this.laudo[i].grau
              ? ' grau ' +
                this.laudo[i].grau +
                ' segundo a classificação de Grannum'
              : ''
          }. `
        );

      if (this.laudo[i].comentariosAdcionaisMeio1)
        folha.push(`${this.laudo[i].comentariosAdcionaisMeio1}`);

      if (folha.length > 0) folha.splice(0, 0, 'Vitalidade fetal');
      this.vitalidadeFetal = [...folha];
      folha = [];

      if (this.laudo.length == 1) folha.push(`Gestação tópica, única. `);
      if (this.laudo.length == 2)
        folha.push(
          `Gestação tópica, gemelar ${
            this.laudo[i].gestacaoTres ? this.laudo[i].gestacaoTres : ''
          }${
            this.laudo[i].gestacaoQuatro && this.laudo[i].gestacaoTres
              ? ', '
              : ''
          }${this.laudo[i].gestacaoQuatro ? this.laudo[i].gestacaoQuatro : ''}.`
        );
      if (this.laudo.length == 3)
        folha.push(
          `Gestação tópica, trigemelar ${
            this.laudo[i].gestacaoTres ? this.laudo[i].gestacaoTres : ''
          }${
            this.laudo[i].gestacaoQuatro && this.laudo[i].gestacaoTres
              ? ', '
              : ''
          }${this.laudo[i].gestacaoQuatro ? this.laudo[i].gestacaoQuatro : ''}.`
        );
      if (this.laudo.length == 4)
        folha.push(
          `Gestação tópica, quadrigemelar ${
            this.laudo[i].gestacaoTres ? this.laudo[i].gestacaoTres : ''
          }${
            this.laudo[i].gestacaoQuatro && this.laudo[i].gestacaoTres
              ? ', '
              : ''
          }${this.laudo[i].gestacaoQuatro ? this.laudo[i].gestacaoQuatro : ''}.`
        );
      if (this.laudo.length == 5) folha.push(`Gestação tópica, quíntuplo `);

      if (this.laudo.length == 2) folha.push(``);

      if (this.laudo.length > 2)
        folha.push(
          `${this.laudo[i].gestacaoCinco ? this.laudo[i].gestacaoCinco : ''}`
        );

      if (this.laudo[i].idadeGestacional2 || this.laudo[i].semanas11)
        folha.push(`Idade gestacional compatível com
        ${
          this.laudo[i].idadeGestacional2
            ? this.laudo[i].idadeGestacional2 +
              (this.laudo[i].idadeGestacional2 > 1 ? ' semanas ' : ' semana ')
            : ''
        }
        ${
          this.laudo[i].idadeGestacional2 && this.laudo[i].semanas11
            ? ' e '
            : ''
        }
        ${
          this.laudo[i].semanas11
            ? this.laudo[i].semanas11 +
              (this.laudo[i].semanas11 > 1 ? ' dias ' : ' dia ')
            : ''
        }
        pela biometria fetal atual.`);

      if (this.laudo[i].dataProvavelParto)
        folha.push(
          `Data provável do parto pela biometria fetal atual (40 semanas): ${this.dataFormatada(
            this.laudo[i].dataProvavelParto
          )}.`
        );
      if (this.laudo[i].dataProvavelParto1Trimestre)
        folha.push(
          `Data provável do parto corrigida por ultrassonografia do 1º trimestre: ${this.dataFormatada(
            this.laudo[i].dataProvavelParto1Trimestre
          )}.`
        );

      // if (
      //   this.laudo[i].gestacaoConclusao &&
      //   this.laudo[i].gestacaoConclusao !=
      //     'Observamos discordância da idade gestacional baseada na ultrassonografia do 1º trimestre e na biometria fetal atual.'
      // )
      //   folha.push(`${this.laudo[i].gestacaoConclusao}`);
      // if (
      //   this.laudo[i].gestacaoConclusao &&
      //   this.laudo[i].gestacaoConclusao ===
      //     'Observamos discordância da idade gestacional baseada na ultrassonografia do 1º trimestre e na biometria fetal atual.'
      // )
      //   folha.push(
      //     `${this.laudo[i].gestacaoConclusao} sugerindo ${this.laudo[i].gestacaoConclusao2}`
      //   );

      if (this.laudo[i].gestacaoConclusao)
        folha.push(
          `${this.laudo[i].gestacaoConclusao}.${
            this.laudo[i].gestacaoConclusao ==
            'Observamos discordância na idade gestacional baseada na ultrassonografia do 1º trimestre e na biometria fetal atual'
              ? ', sugerindo' + this.laudo[i].gestacaoConclusao2
              : ''
          }`
        );

      if (this.laudo[i].gestacaoConclusao3)
        folha.push(`${this.laudo[i].gestacaoConclusao3}.`);
      if (this.laudo[i].comentariosAdcionaisFinal2)
        folha.push(`${this.laudo[i].comentariosAdcionaisFinal2}`);

      if (this.laudo[i].batimentosCardiacosFetais === 'ausentes')
        folha.push(`Óbito fetal.`);

      if (this.laudo[i].batimentosCardiacosFetais === 'ausentes')
        folha.push(
          `Idade gestacional de ${this.laudo[i].idadeGestacional} semanas.`
        );

      if (this.laudo[i].comentariosAdcionaisFinal1)
        folha.push(`${this.laudo[i].comentariosAdcionaisFinal1}`);

      if (folha.length > 0) folha.splice(0, 0, 'Conclusão');
      this.conclusao = [...folha];

      folha = [];
      arrayDeFetos[i] = [
        ...this.gestacaoz,
        ...this.posiçãoFetal,
        ...this.biometriaFetal,
        ...this.vitalidadeFetal,
        ...this.conclusao,
      ];

      todos.push(...this.gestacaoz);
      todos.push(...this.posiçãoFetal);
      todos.push(...this.biometriaFetal);
      todos.push(...this.vitalidadeFetal);
      todos.push(...this.conclusao);
    }
    this.listaDeFetos = arrayDeFetos;

    while (todos.length > 0) {
      this.tamanhoElemento.push(todos.splice(0, 34));
    }
  },

  data() {
    return {
      gestacaoz: [],
      via: '',
      posiçãoFetal: [],
      biometriaFetal: [],
      vitalidadeFetal: [],
      conclusao: [],
      listaDeFetos: [],
      tamanhoElemento: [],
      titulos: [
        'Gestação',
        'Posição fetal',
        'Biometria fetal',
        'Vitalidade fetal',
        'Indicação do exame',
        'Conclusão',
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$default-spacer: 0px;

section {
  text-align: justify;
  margin-left: 10px;
  font-size: 11pt;
  font-family: 'Calibri';

  .header {
    display: flex;
    flex-direction: column;
  }

  h3 {
    text-align: justify;
    margin-left: 0px;
    margin: $default-spacer * 0;
    padding: 0px;
    line-height: 15px;
  }

  div {
    margin-left: 0px;
  }

  h4 {
    text-align: justify;
    margin-left: 0px;
    margin: 0px;
    margin-top: 0px;
    line-height: 5px;
  }

  p {
    word-wrap: break-word;
    text-align: justify;
    font-family: 'Calibri';
    margin-left: 0px;

    margin-top: 1x;
    padding: 2px;
  }
  br {
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    line-height: 100%;
  }
  span {
    margin: $default-spacer * 0;
    margin-top: 15px;
    margin-left: 0px;
    line-height: 5px;
  }
  footer {
    //position: absolute;
    bottom: 0;
    margin-left: 0px;

    width: 80%;
    height: 100px;
    text-align: left;
    line-height: 20px;
  }
}
</style>
